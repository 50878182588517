<template>
  <v-container>
    <v-card class="pa-10">
      <v-card-title class="pa-0 mb-6">
        <v-checkbox
          v-model="showDisabledCampaigns"
          label="Show disabled campaigns"
        />
        <v-spacer />
        <app-button @click="$router.push({ name: 'AdminManageCreateCampaign' })">
          New campaign
        </app-button>
      </v-card-title>
      <v-data-table
        :options.sync="pagination"
        :headers="headers"
        :items="filterCampaigns"
        :loading="isLoading.campaigns"
        :no-data-text="noData"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Campaigns per page',
          itemsPerPageOptions: pagination.rowsPerPageItems
        }"
      >
        <template #body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>
                {{ item.title }}
              </td>
              <td v-if="!item.disabled && showDisabledCampaigns">
                <v-icon green>
                  check_circle
                </v-icon>
              </td>
              <td v-if="item.disabled && showDisabledCampaigns">
                <v-icon>block</v-icon>
              </td>
              <td>
                <v-menu
                  bottom
                  left
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="option in filterMenuOptions(item.disabled)"
                      :key="option.name"
                      @click="menuActions(option.name, item)"
                    >
                      {{ option.name }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { config } from '@/utils';
import CampaignService from '@/services/campaignService';

export default {
  data() {
    return {
      pagination: {
        rowsPerPage: 12,
        rowsPerPageItems: [12, 24, 36, { text: 'All', value: -1 }]
      },
      menuOptions: [
        { name: 'View' },
        { name: 'Edit' },
        { name: 'Disable', disable: false },
        { name: 'Enable', disable: true }
      ],
      dialogDelete: false,
      showDisabledCampaigns: false
    };
  },
  url: {
    pagination: {
      param: 'pagination',
      noHistory: true
    },
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'campaigns'
    ]),
    noData() {
      return this.isLoading.campaigns ? 'loading...' : 'No campaigns.';
    },
    headers() {
      const customHeaders = [
        { text: 'Title', value: 'title', align: 'left', width: this.showDisabledCampaigns ? '80%' : '100%' },
        { sortable: false, width: 30 }
      ];
      if (this.showDisabledCampaigns) {
        customHeaders.splice(1, 0, {
          text: 'Enabled', value: 'disabled', align: 'left'
        });
      }
      return customHeaders;
    },
    filterCampaigns() {
      return this.showDisabledCampaigns
        ? this.campaigns : this.campaigns.filter((c) => !c.disabled);
    }
  },
  methods: {
    menuActions(option, campaign) {
      switch (option) {
        case 'View':
          window.open(`${config.portalUrl}/#/campaign/${campaign.id}`, '_blank');
          break;
        case 'Edit':
          this.$router.push({ path: `manage/edit/${campaign.id}` });
          break;
        case 'Enable':
        case 'Disable':
          CampaignService.toggleCampaignStatus(campaign);
          break;
        default:
          break;
      }
    },
    filterMenuOptions(disabled) {
      return this.menuOptions
        .filter((option) => option.disable === undefined || !option.disable === !disabled);
    }
  }
};
</script>

<style scoped>
  .list__tile__title {
    text-align: center;
  }

  table.table tbody td:last-of-type {
    padding: 0;
    text-align: right;
  }

  .list {
    min-width: 80px;
  }
</style>
