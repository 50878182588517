var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-10"},[_c('v-card-title',{staticClass:"pa-0 mb-6"},[_c('v-checkbox',{attrs:{"label":"Show disabled campaigns"},model:{value:(_vm.showDisabledCampaigns),callback:function ($$v) {_vm.showDisabledCampaigns=$$v},expression:"showDisabledCampaigns"}}),_c('v-spacer'),_c('app-button',{on:{"click":function($event){return _vm.$router.push({ name: 'AdminManageCreateCampaign' })}}},[_vm._v(" New campaign ")])],1),_c('v-data-table',{staticClass:"table-container",attrs:{"options":_vm.pagination,"headers":_vm.headers,"items":_vm.filterCampaigns,"loading":_vm.isLoading.campaigns,"no-data-text":_vm.noData,"footer-props":{
        itemsPerPageText: 'Campaigns per page',
        itemsPerPageOptions: _vm.pagination.rowsPerPageItems
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.title)+" ")]),(!item.disabled && _vm.showDisabledCampaigns)?_c('td',[_c('v-icon',{attrs:{"green":""}},[_vm._v(" check_circle ")])],1):_vm._e(),(item.disabled && _vm.showDisabledCampaigns)?_c('td',[_c('v-icon',[_vm._v("block")])],1):_vm._e(),_c('td',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.filterMenuOptions(item.disabled)),function(option){return _c('v-list-item',{key:option.name,on:{"click":function($event){return _vm.menuActions(option.name, item)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1)])}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }